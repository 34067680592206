<template>
  <div class="mt-4">
    <button
      v-if="$guards.has_perm('entity.add_industry')"
      @click="$router.push({ name: 'New Industry' })"
      class="btn btn-sm btn-default"
    >
      New
    </button>
    <div class="card shadow w-100 mt-2 p-4">
      <data-table
        v-if="data_loaded"
        id="products_list"
        :options="dtb_options.extra_options"
        :actions="dtb_options.actions"
        :data="dtb_options.data"
        :columns="dtb_options.columns"
      />
    </div>

    <!-- Update Industry Modal -->
  </div>
</template>

<script>
import axios from "axios";
import DataTable from "@/components/Datatable";
import { alert } from "../../utils/alertUtils";

export default {
  name: "Industries",
  components: {
    "data-table": DataTable,
  },
  data() {
    return {
      industries: [],
      data_loaded: false,
      dtb_options: {
        data: [],
        actions: this.$guards.has_perm("entity.change_industry")
          ? [
              // {
              //   btnText: "",
              //   btnClass: "btn btn-white text-danger btn-sm",
              //   iconClass: "fas fa-trash",
              //   btnFn: (uuid) => {
              //     this.delete_industry(uuid)
              //   },
              //   btnParamName: "uuid",
              // },
               {
                btnText: "Detail",
                btnClass: "btn btn-default text-white btn-sm",
                iconClass: "fas fa-info-circle",
                btnFn: (uuid) => {
                  this.$router.push({name:"IndustryDetail",params:{uuid:uuid}})
                },
                btnParamName: "uuid",
              },
            ]
          : [],
        columns: [{ title: "Name", data: "name" }],
        extra_options: {},
      },
    };
  },
  methods: {
    fetch_industries: function () {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/industries`)
        .then((res) => {
          console.log(res);
          this.dtb_options.data = res.data;
          this.data_loaded = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    delete_industry: function (uuid) {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/industries/${uuid}`;
      axios
        .delete(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then(() => {
          alert.success_center("Industry Deleted successfully.");
          this.fetch_industries()
        })
        .catch(() => {
          alert.error("An error occured, in deleting industry");
        });
    },
    // update_industry:function(uuid){
    //   var url = `${process.env.VUE_APP_BACKEND_URL}/api/industries/${uuid}`
    //   axios.put(url,this.dtb_options.data,)
    // },
  },
  mounted() {
    this.fetch_industries();
  },
  created() {
    // this.fetch_industries();
  },
};
</script>

<style>
</style>